import React from 'react';
import 'twin.macro';

import ButtonLink from './ButtonLink';
import IconArrowDown from './Icon/ArrowDown';

const LearnMore = ({ className }: { className?: string }) => (
  <ButtonLink
    className={className}
    hue="pink"
    to="howItWorks"
    tw="flex items-center self-center"
    variant="transparent"
  >
    Learn More{' '}
    <IconArrowDown tw="h-3 w-4 sm:(h-4 w-4) transform -rotate-90 ml-2" />
  </ButtonLink>
);

export default LearnMore;

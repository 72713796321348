import Image from 'next/image';
import React from 'react';
import tw from 'twin.macro';

import howItWorks1Image from '../../public/images/howItWorks1.svg';
import howItWorks2Image from '../../public/images/howItWorks2.svg';
import howItWorks3Image from '../../public/images/howItWorks3.svg';
import howItWorks4Image from '../../public/images/howItWorks4.svg';
import { ReactComponent as HowItWorksBackground } from '../images/howItWorksBackground.svg';
import { ReactComponent as HowItWorksDecoration } from '../images/howItWorksDecoration.svg';
import LearnMore from './LearnMore';

const Card = tw.li`bg-pink-100 rounded-xl px-2 py-2 odd-of-type:(mb-8 md:mb-20) even-of-type:(mt-8 md:mt-20)`;
const CardImage = tw.div`relative`;
const CardText = tw.h3`md:mt-4 text-center font-bold text-pink-700 text-xs lg:text-base leading-normal`;

interface HowItWorksProps {
  className?: string;
  link?: boolean;
  // Small is currently unused
  size?: 'small' | 'normal';
}

const HowItWorks = ({
  className,
  link = true,
  size = 'normal',
}: HowItWorksProps) => {
  const isNormal = size === 'normal';
  const cardCss = isNormal && tw`md:rounded-3xl md:py-4 lg:py-8 lg:px-4`;
  const cardTextCss = isNormal && tw`md:text-lg lg:text-xl`;
  const cardImageCss = size === 'normal' ? tw`h-20 md:h-28 lg:h-40` : tw`h-16`;
  const suppliesCardImageCSs =
    size === 'normal' ? tw`h-28 md:h-36 lg:h-48` : tw`h-20`;

  return (
    <div className={className} tw="flex flex-col">
      <h2 tw="text-center font-serif text-3xl text-pink-600">
        How Chartsy Works
      </h2>
      <div tw="relative overflow-hidden mt-4 md:mt-12 lg:mt-20">
        <div tw="absolute w-full h-full">
          <HowItWorksBackground tw="w-full h-full text-pink-200" />
        </div>
        <ol
          css={isNormal && tw`md:gap-8 lg:gap-16 lg:py-8`}
          tw="relative items-start grid grid-cols-4 gap-4 py-4"
        >
          <Card css={cardCss}>
            <CardImage css={cardImageCss}>
              <Image
                alt="mobile phone adding item to basket"
                layout="fill"
                objectFit="contain"
                src={howItWorks1Image}
              />
            </CardImage>
            <CardText css={cardTextCss}>
              Get a Chartsy digital cross stitch chart
            </CardText>
          </Card>
          <Card css={cardCss}>
            <CardImage css={cardImageCss}>
              <Image
                alt="desktop web browser with customization interface"
                layout="fill"
                objectFit="contain"
                src={howItWorks2Image}
              />
            </CardImage>
            <CardText css={cardTextCss}>
              Customize fabric, thread, size and frame
            </CardText>
          </Card>
          <Card css={cardCss} tw="bg-white">
            <CardImage css={suppliesCardImageCSs}>
              <Image
                alt="person leaving store with bags"
                layout="fill"
                objectFit="contain"
                src={howItWorks3Image}
              />
            </CardImage>
            <CardText css={cardTextCss} tw="text-pink-600">
              Gather your supplies
            </CardText>
          </Card>
          <Card css={cardCss} tw="relative">
            <HowItWorksDecoration
              css={isNormal && tw`lg:(h-16 w-16 -left-8 -top-8)!`}
              tw="absolute text-pink-200 -left-4 -top-4 h-8 w-8 md:(h-12 w-12) md:(-left-6 -top-6)"
            />
            <CardImage css={cardImageCss}>
              <Image
                alt="tablet with stylus being used to track stitches"
                layout="fill"
                objectFit="contain"
                src={howItWorks4Image}
              />
            </CardImage>
            <CardText css={cardTextCss}>Stitch and track with Chartsy</CardText>
          </Card>
        </ol>
      </div>
      {link && <LearnMore tw="sm:mt-4" />}
    </div>
  );
};

export default HowItWorks;

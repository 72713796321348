import React from 'react';

function IconArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.15 12.993l9.352-9.832A1.811 1.811 0 0020.878.666l-8.453 8.902a.597.597 0 01-.868 0L3.103.666A1.799 1.799 0 10.494 3.144l9.377 9.873a3.013 3.013 0 004.278-.024z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconArrowDown;
